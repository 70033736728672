import { useCallback, useRef } from 'react';
import Defer, { DeferredPromise } from 'p-defer';

export type DeferFn = (
	/**
	 * Optional promises which need resolve. Promises are passed to `Promise.all`.
	 */
	promises?: Promise<any> | Promise<any>[],

	/**
	 * Optional fulfilment handler.
	 *
	 * @param results Results are from the promises provided by the `promises` param.
	 */
	onFulfilled?: (results?: any[]) => any,

	/**
	 * Optional rejection handler.
	 *
	 * @param reason The 'reason' the promise was rejected.
	 */
	onRejected?: (reason?: any) => any
) => Promise<any[]>;

/**
 * Holds a promise which is manually resolved or rejected.
 * This hook should be used carefully and sparingly,
 * it leverages an anti-pattern and in *most* cases it shouldn't be required.
 * Note: if providing a `promise`, it is executed immediately, the deferral is
 * for an internal no-op promise.
 *
 * @example
 *   const { defer, resolve, reject } = useDeferred();
 *
 *   useEffect(() => {
 *     defer(someAsyncFn());
 *   }, [defer, someAsyncFn]);
 *
 *   // Resolve promise after a minimum of 5 seconds
 *   setTimeout(() => {
 *     resolve();
 *   }, 5000);
 */
export const useDeferred = () => {
	const ref = useRef<{ deferred: DeferredPromise<void>[] }>({
		deferred: [],
	});

	/**
	 * Resolves the deferred promise created by `defer()`.
	 */
	const resolve = useCallback(() => {
		ref.current.deferred?.forEach(d => d.resolve());
		ref.current.deferred = [];
	}, []);

	/**
	 * Rejects the deferred promise created by `defer()`.
	 *
	 * @param reason Optionally provide a reason the promise is rejected.
	 */
	const reject = useCallback((reason?: any) => {
		ref.current.deferred?.forEach(d => d.reject(reason));
		ref.current.deferred = [];
	}, []);

	/**
	 * Invokes a manually resolved promise.
	 *
	 * @param promises Optional promises which need resolve. Promises are passed to `Promise.all`.
	 * @param onFulfilled Optional fulfilment handler. Receives the results from the promises provided by the `promises` param.
	 * @param onRejected Optional rejection handler. Receives the 'reason' the promise was rejected.
	 */
	const defer = useCallback<DeferFn>((_providedPromises, onFulfilled, onRejected) => {
		const providedPromises = [_providedPromises ?? []].flat();

		const deferred = Defer<void>();
		ref.current.deferred.unshift(deferred);

		const allPromises: Promise<any>[] = [deferred.promise];
		if (providedPromises.length) allPromises.push(...providedPromises);

		return Promise.all(allPromises).then(results => {
			// Pass only the results from the provided promises
			const _results = results.slice(-providedPromises.length) ?? [];
			return onFulfilled ? onFulfilled(_results) : results;
		}, onRejected);
	}, []);

	return { defer, resolve, reject, hasDeferred: ref.current.deferred.length > 0 };
};
