import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDeepCompareEffect, useEffectOnce, useLatest } from 'react-use';
import {
	AccountInfo,
	AuthenticationResult,
	Configuration,
	InteractionRequiredAuthError,
	PublicClientApplication,
} from '@azure/msal-browser';
import mem from 'mem';
import createPersistedState from 'use-persisted-state';

import { DeferFn, useDeferred } from '../utils/defer';

import { AuthConfig } from './auth-config';
import { AuthContextValue } from './auth-context-value';
import { AuthUserId } from './auth-user-id';
import { NotLoggedInError } from './NotLoggedInError';

export interface AuthProviderProps {
	children: React.ReactNode;
	config: AuthConfig;
}

const AuthContext = createContext<AuthContextValue>({
	isAuthenticated: () => false,
	isAuthContextReady: () => false,
	waitAuthContextReady: () => Promise.reject(),
	getAccessToken: () => {
		throw new Error('The auth context has not been initialised yet');
	},
	login: () => {
		throw new Error('The auth context has not been initialised yet');
	},
	logout: () => {
		throw new Error('The auth context has not been initialised yet');
	},
});

export const useAuthContext = () => useContext(AuthContext);

const usePersistedTokenState = createPersistedState('auth-token-response', window.sessionStorage);

interface GetAccessTokenParams {
	userAccountRef: { readonly current: AccountInfo | undefined };
	publicClient: PublicClientApplication;
	scopes?: string[];
}

// Prevent many requests for the same token occurring (typically before the token has been cached by `publicClient`)
const memGetAccessToken = mem(
	async ({ userAccountRef, publicClient, scopes = [] }: GetAccessTokenParams) => {
		let accessToken = null;

		if (!userAccountRef.current) {
			throw new NotLoggedInError('A user must be logged in before attempting to retrieve an access token.');
		}

		const request = {
			account: userAccountRef.current,
			scopes,
		};

		try {
			const authenticationResult = await publicClient.acquireTokenSilent(request);
			accessToken = authenticationResult.accessToken;
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				await publicClient.acquireTokenRedirect(request);
			} else {
				throw error;
			}
		}

		return accessToken;
	},
	{
		cacheKey: ([params]) =>
			`${params.userAccountRef.current?.username ?? 'unknown'}_${(params.scopes ?? []).join(',')}`,
		maxAge: 10e3, // 10 seconds
	}
);

// Prevent multiple deferred promises, they are all for the same thing
const memWaitAuthContextReady = mem(
	async (_isAuthContextReady: boolean, defer: DeferFn) => {
		if (_isAuthContextReady) return Promise.resolve();
		await defer();
	},
	{ maxAge: 10000 }
);

type ProviderAuthConfig = AuthConfig & Pick<Configuration, 'cache'>;

const configFactory = (config: AuthConfig): ProviderAuthConfig => ({
	...config,
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
});

export const AuthProvider = ({ children, config }: AuthProviderProps) => {
	const [authToken, setAuthToken] = usePersistedTokenState<AuthenticationResult | null>(null);
	const [userIdentity, setUserIdentity] = useState<AuthUserId>();
	const [authError, setAuthError] = useState<any>();
	const [userAccount, setUserAccount] = useState<AccountInfo>();
	// Use ref access to prevent `getAccessToken` from calling outdated state.
	const userAccountRef = useLatest(userAccount);

	const [msalConfig, setMsalConfig] = useState<ProviderAuthConfig>(configFactory(config));

	// Update `msalConfig` if `config` changes
	useDeepCompareEffect(() => {
		setMsalConfig(configFactory(config));
	}, [config]);

	const publicClient = useMemo(() => new PublicClientApplication(msalConfig), [msalConfig]);

	const isAuthenticated = () => {
		const accounts = publicClient.getAllAccounts();
		return accounts.length !== 0 && !!userAccount;
	};

	const mapIdTokenToUserIdentityProperty = (idTokenClaims: any) => {
		const mappedUserIdentity: AuthUserId = {
			name: idTokenClaims.name,
			userName: idTokenClaims.preferred_username,
			email: idTokenClaims.preferred_username,
		};
		return mappedUserIdentity;
	};

	const initialiseAuthContextValues = (tokenResponse: AuthenticationResult) => {
		const userId = mapIdTokenToUserIdentityProperty(tokenResponse.idTokenClaims);
		setUserIdentity(userId);
		setUserAccount(tokenResponse.account!);
	};

	const retrieveAccount = async () => {
		try {
			let tokenResponse: AuthenticationResult | null;

			if (!isAuthenticated()) {
				tokenResponse = await publicClient.handleRedirectPromise();
				setAuthToken(tokenResponse);
			} else {
				tokenResponse = authToken;
			}

			if (tokenResponse) {
				initialiseAuthContextValues(tokenResponse);
			} else {
				// Note: The redirection will exit the app for the login process
				publicClient.loginRedirect();
			}
		} catch (error) {
			setAuthError(error);
			throw error;
		}
	};

	useEffectOnce(() => {
		retrieveAccount();
	});

	const isAuthContextReady = () => {
		return isAuthenticated() && !!userAccount && !!userIdentity && !authError;
	};
	const _isAuthContextReady = isAuthContextReady();

	const login = () => {
		retrieveAccount();
	};

	const logout = () => {
		publicClient.logout();
	};

	const getAccessToken = useCallback(
		async (scopes?: string[]) =>
			memGetAccessToken({
				userAccountRef,
				publicClient,
				scopes: scopes ?? msalConfig.auth.defaultScopes,
			}),
		[msalConfig.auth.defaultScopes, publicClient, userAccountRef]
	);

	//#region waitAuthContextReady
	const { defer, resolve } = useDeferred();

	useEffect(() => {
		if (!_isAuthContextReady) return;
		resolve();
	}, [_isAuthContextReady, resolve]);

	const waitAuthContextReady = useCallback(
		async () => memWaitAuthContextReady(_isAuthContextReady, defer),
		[_isAuthContextReady, defer]
	);
	//#endregion

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				isAuthContextReady,
				waitAuthContextReady,
				userAccount,
				userIdentity,
				authError,
				getAccessToken,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
