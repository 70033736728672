import ky from 'ky';
import { defaultsDeep, forOwn } from 'lodash-es';
import urljoin from 'url-join';

import { AuthConfig } from '../authn/auth-config';

export interface CoreConfig {
	api: {
		baseUrl: string;
		currentUser: {
			currentUrl: string;
			abilitiesUrl: string;
		};
		user: {
			currentUrl: string;
		};
		application: {
			currentUrl: string;
		};
		groups: {
			currentUrl: string;
		};
		graph: {
			usersUrl: string;
		};
		roleAssignments: {
			currentUrl: string;
		};
		roleAbilities: {
			currentUrl: string;
		};
		roles: {
			currentUrl: string;
		};
		abilities: {
			currentUrl: string;
		};
		securityContext: {
			resourceTypesUrl: string;
		};
		notifications: {
			listUrl: string;
			unreadUrl: string;
		};
		products: {
			currentUrl: string;
			voxelUrl: string;
		};
		memberships: {
			currentUrl: string;
		};
		applicationMemberships: {
			currentUrl: string;
		};
		/**
		 * @deprecated since version 10 will be removed in version 11. Please use modulePreferences.
		 */
		modulePerferences?: {
			currentUrl: string;
		};
		modulePreferences: {
			currentUrl: string;
		};
		/**
		 * @deprecated since version 10 will be removed in version 11. Please use userPreferences.
		 */
		userPerferences?: {
			currentUrl: string;
		};
		userPreferences: {
			currentUrl: string;
		};
		workflows: {
			currentUrl: string;
		};
		serviceNow: {
			currentUrl: string;
		};
	};
	hub: {
		baseHubEndpoint: string;
		notifications: {
			hubEndpoint: string;
			hubMethod: string;
		};
	};
	features: {
		notifications?: boolean;
		abilities?: boolean;
		permissionsUI?: boolean;
		productsService?: boolean;
		appPreferences?: boolean;
		userPreferences?: boolean;
		serviceNowSupport?: boolean;
	};
}

export interface ApplicationInsightsConfig {
	connectionString: string;
}

export interface Config extends AuthConfig {
	core: CoreConfig;
	applicationInsights?: ApplicationInsightsConfig;
	project?: { [key: string]: any };
}

type ConfigValue = boolean | string | number | { [key: string]: any };
type ConfigObject = { [key: string]: ConfigValue };

const ENV_DEFAULTS: Config = {
	auth: {
		clientId: '',
		authority: '',
		redirectUri: '',
		defaultScopes: [],
	},
	core: {
		api: {
			baseUrl: '',
			currentUser: {
				currentUrl: 'v1/CurrentUser',
				abilitiesUrl: 'v1/CurrentUser/Abilities',
			},
			user: {
				currentUrl: 'v1/Users',
			},
			application: {
				currentUrl: 'v1/AzureApplications',
			},
			groups: {
				currentUrl: 'v1/Groups',
			},
			graph: {
				usersUrl: 'v1/GraphUsers',
			},
			roleAssignments: {
				currentUrl: 'v1/RoleAssignments',
			},
			roleAbilities: {
				currentUrl: 'v1/RoleAbilities',
			},
			roles: {
				currentUrl: 'v1/Roles',
			},
			abilities: {
				currentUrl: 'v1/Abilities',
			},
			securityContext: {
				resourceTypesUrl: 'v1/SecurityContext/ResourceTypes',
			},
			notifications: {
				listUrl: '',
				unreadUrl: '',
			},
			products: {
				currentUrl: 'v1/Products',
				voxelUrl: 'https://voxel.angloamerican.com/',
			},
			memberships: {
				currentUrl: 'v1/Memberships',
			},
			applicationMemberships: {
				currentUrl: 'v1/AzureApplicationMemberships',
			},
			modulePreferences: {
				currentUrl: '/v1/ModulePreferences',
			},
			userPreferences: {
				currentUrl: '/v1/UserPreferences',
			},
			workflows: {
				currentUrl: '/ProcessWorkflow',
			},
			serviceNow: {
				currentUrl: '/v1/ServiceNow',
			},
		},
		hub: {
			baseHubEndpoint: '',
			notifications: {
				hubEndpoint: '',
				hubMethod: '',
			},
		},
		features: {
			notifications: false,
			abilities: false,
			permissionsUI: false,
			productsService: false,
			appPreferences: false,
			userPreferences: false,
		},
	},
	applicationInsights: {
		connectionString: '',
	},
	project: {},
};

/**
 * Endpoint builder. If string starts with `http://` or `https://`
 * the string will be returned verbatim; otherwise it's prepended with
 * the API baseUri config string.
 */
export const withBaseUrl = (url = '', baseUrl = CONFIG.core.api.baseUrl): string =>
	url.startsWith('http://') || url.startsWith('https://') ? url : urljoin(baseUrl, url);

/**
 * Loops through properties looking for configs ending with `Url` or `Uri`
 * which have string values and automatically applies the configured `baseUrl`.
 *
 * Makes consuming the config simpler without needing to always check and/or
 * manually apply the baseUrl.
 *
 * @param config
 * @param baseUrl
 * @param keys
 */
const applyUrls = (config: ConfigObject, baseUrl: string, keys: string[]): ConfigObject =>
	forOwn(config, (value, key) => {
		const keyLowerCase = key.toLowerCase();
		const endsWithKey = keys.some(k => keyLowerCase.endsWith(k));

		if (endsWithKey && typeof value === 'string') {
			config[key] = withBaseUrl(value.toString(), baseUrl);
		} else if (typeof value === 'object') buildConfig(value, baseUrl, keys);
	});

/**
 * Builds the application config
 * @param config
 * @param baseUrl
 * @param keys
 */
const buildConfig = (config: ConfigObject, baseUrl: string, keys: string[]): ConfigObject =>
	applyUrls(config, baseUrl, keys);

/**
 * Global Application Configuration
 * Object frozen and cannot be modified.
 */
export let CONFIG: Config;

export const loadConfig = async () => {
	const nodeEnvironment = import.meta.env.MODE;
	const publicPath = import.meta.env.BASE_URL;

	const jsonFileUrl =
		!nodeEnvironment || nodeEnvironment === 'development' ? `${publicPath}env.local.json` : `${publicPath}env.json`;

	const response = await ky.get(jsonFileUrl);
	const jsonConfig = await response.json();

	if (jsonConfig.core.api.userPerferences && jsonConfig.core.api.userPreferences) {
		throw Error(
			"Both 'userPerferences' and 'userPreferences' were used in your env.json. Please update your config to use 'userPreferences'."
		);
	} else if (jsonConfig.core.api.userPerferences) {
		console.warn("'userPerferences is deprecated. Please update your env.json to use 'userPreferences' instead.");
		jsonConfig.core.api.userPreferences = jsonConfig.core.api.userPerferences;
		delete jsonConfig.core.api.userPerferences;
	}

	if (jsonConfig.core.api.modulePerferences && jsonConfig.core.api.modulePreferences) {
		throw Error(
			"Both 'modulePerferences' and 'modulePreferences' were used in your env.json. Please update your config to use 'modulePreferences'."
		);
	} else if (jsonConfig.core.api.modulePerferences) {
		console.warn("'modulePerferences is deprecated. Please update your env.json to use 'modulePreferences' instead.");
		jsonConfig.core.api.modulePreferences = jsonConfig.core.api.modulePerferences;
		delete jsonConfig.core.api.modulePerferences;
	}

	CONFIG = Object.freeze(
		((): Config => {
			const config = defaultsDeep({}, jsonConfig, ENV_DEFAULTS);

			// Apply Urls
			let built: unknown = buildConfig(config, config.core.api.baseUrl, ['url', 'uri']);

			// Apply Hub Endpoints
			built = buildConfig(built as ConfigObject, config.core.hub.baseHubEndpoint, ['hubendpoint']);
			return built as Config;
		})()
	);

	return CONFIG;
};
