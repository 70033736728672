import React, { LazyExoticComponent, Suspense } from 'react';
import ReactDOM from 'react-dom';

import { loadConfig } from '../framework/config';

import { AuthProvider, useAuthContext } from './auth-context';

/**
 * Runs the application entry point with authentication.
 *
 * @param element the React root element to render
 * @param rootElementId the DOM element id to render the React component to
 */
export const runWithAuth = async (Element: LazyExoticComponent<any>, rootElementId: string) => {
	const config = await loadConfig();
	const authorisedApplication = (
		<AuthProvider config={config}>
			<Suspense fallback={<div></div>}>
				<Element />
			</Suspense>
		</AuthProvider>
	);

	ReactDOM.render(authorisedApplication, document.getElementById(rootElementId));
};
