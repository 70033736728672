import { capitalCase } from 'change-case';
import { formatISO, isValid } from 'date-fns';
import { titleCase as titleCaseImport } from 'title-case';

const userLocale = Intl.NumberFormat().resolvedOptions().locale;
const groupedNumberFormat = new Intl.NumberFormat(userLocale, {
	useGrouping: true,
});

export function formatNumber(value: number) {
	return groupedNumberFormat.format(value);
}

export function round(value: number, decimals: number) {
	return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function formatDate(date?: Date | null) {
	if (date === undefined || date === null || !isValid(date)) return '';

	return formatISO(date, { representation: 'date' });
}

export function titleCase(title: string): string {
	return capitalCase(titleCaseImport(title));
}

export function resourcePropertyGroupTitle(title: string): string {
	if (title.toLowerCase() === 'none') return 'Operating Parameters';
	return titleCase(title);
}

export const resourceClassNameFormatter = (name?: string) => (name ? titleCase(name) : '');

export function enumeratedKeyFromValue<T extends { [key: string]: string | number } = any>(
	enumerator: T,
	value?: string | number
): keyof T | undefined {
	if (value === undefined) return;
	const keys = Object.keys(enumerator).filter(k => Number.isNaN(Number.parseInt(k)));
	const values = Object.values(enumerator).filter(k => typeof k === 'string');
	const index = typeof value === 'string' ? values.indexOf(value) : value;
	return keys[index];
}
