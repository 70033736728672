import { lazy } from 'react';
import * as React from 'react';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';

import { runWithAuth } from 'src/toolkit/core';

import * as serviceWorker from './serviceWorker';

//polyfill for 'global undefined'
if (typeof (window as any).global === 'undefined') {
	(window as any).global = window;
}
if (import.meta.env.MODE !== 'development') {
	//polyfill for jsxRuntime: classic - 'React is not defined' error
	window.React = React;
}

if (import.meta.env.MODE === 'development') {
	// @ts-ignore
	import('./dev-mode-only.css');

	const { default: whyDidYouRender } = await import('@welldone-software/why-did-you-render');
	whyDidYouRender(React, {
		trackAllPureComponents: false,
		logOnDifferentValues: true,
	});

	//see https://github.com/simbathesailor/use-what-changed
	setUseWhatChange(true);
}

const App = lazy(() => import('./App'));
runWithAuth(App, 'root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
